import { CardContent, CardHeader } from '@mui/material';
import ImageNotFound from '@/src/assets/common/images/image_not_found.jpg';
import { getMonthDayYearValue } from '@/src/utilities/helpers/dateParser';
import { CardAvatar, ConstructionCardWrapper, ConstructionCardFooterText } from './ConstructionCard.styled';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { getTenant } from '@/src/utilities/helpers/utils';

const ConstructionCard = (props: Props) => {
  const { textReload, id, title, creationDate, coverImageUrl, subtitle, handleClick } = props;
  return (
    <ConstructionCardWrapper onClick={(event) => handleClick(event, id)} key={id}>
      <CardHeader
        avatar={<CardAvatar aria-label="card-label">{getTenant()}</CardAvatar>}
        title={textReload(title)}
        titleTypographyProps={{
          fontWeight: 'bold',
          width: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        subheader={getMonthDayYearValue(creationDate)}
      />
      <CustomCardMedia key={id} src={coverImageUrl ?? ImageNotFound} alt={title} height="180px" />
      <CardContent>
        <ConstructionCardFooterText variant="body2">{textReload(subtitle)}</ConstructionCardFooterText>
      </CardContent>
    </ConstructionCardWrapper>
  );
};

const propTypes = {};

interface ExtraProps {
  textReload: (text: string) => string;
  id: number;
  title: string;
  creationDate: number;
  coverImageUrl: string;
  subtitle: string;
  handleClick: (event: any, id: number) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
ConstructionCard.propTypes = propTypes;

export default ConstructionCard;
