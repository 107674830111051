import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { createActivity, editActivity, getAmenities, getActivityById } from '@/src/api/endpoints/bookings';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import CustomInput from '@/src/components/forms/CustomInput';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';
import { validate } from './ActivitiesForm.validate';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { ActivitiesFormData, ActivitiesFormProps } from '../types';
import CustomCurrencyInput from '@/src/components/forms/CustomCurrencyInput';

const { VITE_TENANT_ID } = getEnvVariables();

const ActivitiesForm = ({ row, isEdit = false, close, setSnackBarMessage }: ActivitiesFormProps) => {
  const { t } = useTranslation();
  const { data, isLoading: getAmenitiesLoading } = useQuery(['activities'], () =>
    getAmenities({ page: 0, pageSize: 100000 }),
  );

  const { data: activitiesDetail, isFetching: getActivitiesDetailLoading } = useQuery(
    ['activityDetail', row?.id],
    () => getActivityById(row?.id),
    {
      enabled: isEdit && row?.id !== null,
      select: (responseData) => responseData?.data.agendas[0] ?? {},
    },
  );

  const [errorMessage, setErrorMessage] = useState('');
  const createActivityMutation = useMutation(createActivity, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_agenda_creation_snackbar_text', { ns: 'bookings' }));
    },
  });

  const editActivityMutation = useMutation(editActivity, {
    onError: (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: () => {
      close();
      setSnackBarMessage(t('successful_agenda_edition_snackbar_text', { ns: 'bookings' }));
    },
  });

  const formLoading = getAmenitiesLoading || getActivitiesDetailLoading;
  const formIsSubmitting = createActivityMutation.isLoading || editActivityMutation.isLoading;

  const onSubmit = (formData: FormikValues) => {
    const body = {
      ...formData,
      required_deposit_amount:
        formData.required_deposit_amount !== '' ? parseFloat(formData.required_deposit_amount) : null,
      cleaning_fee_amount: formData.cleaning_fee_amount !== '' ? parseFloat(formData.cleaning_fee_amount) : null,
    } as ActivitiesFormData;
    if (isEdit) return editActivityMutation.mutate({ id: row?.id, body });
    return createActivityMutation.mutate(body);
  };
  const getInitialValues = () => ({
    ignore_max_reservation: false,
    business_guid: VITE_TENANT_ID,
    activity_id: activitiesDetail?.activity.id ?? 0,
    name: activitiesDetail?.name ?? '',
    min_reservation_time: activitiesDetail?.min_reservation_time ?? 1,
    max_reservation_time: activitiesDetail?.max_reservation_time ?? 7,
    required_deposit_amount: activitiesDetail?.required_deposit_amount ?? '',
    cleaning_fee_amount: activitiesDetail?.cleaning_fee_amount ?? '',
    has_cleaning_fee: activitiesDetail?.has_cleaning_fee ?? false,
    required_deposit: activitiesDetail?.required_deposit ?? false,
  });
  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t }),
  });

  const handleCheckCleaningFee = () => {
    setFieldValue('cleaning_fee_amount', '');
    setFieldValue('has_cleaning_fee', !values.has_cleaning_fee);
  };
  const handleCheckRequestDeposit = () => {
    setFieldValue('required_deposit_amount', '');
    setFieldValue('required_deposit', !values.required_deposit);
  };
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px' }}>
      {formLoading ? (
        <CircularSpinner />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} />
          <Grid item xs={12} sm={12} md={12}>
            {data && (!isEdit || (isEdit && values.activity_id)) && (
              <Autocomplete
                id="activities-select"
                size="small"
                options={data?.data.activities}
                autoHighlight
                clearOnEscape
                onChange={(e: any, value: any) => {
                  setFieldValue('activity_id', value.id);
                }}
                value={data?.data.activities.find((activity: any) => activity.id === values?.activity_id) || null}
                getOptionLabel={(option) => option.description}
                renderOption={(props, option) => (
                  <Box component="li" key={option.id} {...props}>
                    {option.description}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoFocus
                    label={`${t('agendas_form_activity_field_label', { ns: 'bookings' })} * `}
                    placeholder={t('agendas_form_activity_field_placeholder', { ns: 'bookings' })}
                    error={errors.activity_id !== undefined}
                    helperText={errors.activity_id?.toString()}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <CustomInput
              field="name"
              errors={errors}
              label={t('agendas_form_name_field_label', { ns: 'bookings' })}
              placeholder={t('agendas_form_name_field_placeholder', { ns: 'bookings' })}
              setFieldValue={setFieldValue}
              values={values}
              maxLength={50}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomInput
              inputType="number"
              field="min_reservation_time"
              errors={errors}
              label={t('agendas_form_min_reservation_time_field_label', { ns: 'bookings' })}
              placeholder={t('agendas_form_min_reservation_time_field_placeholder', { ns: 'bookings' })}
              setFieldValue={setFieldValue}
              values={values}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomInput
              inputType="number"
              field="max_reservation_time"
              errors={errors}
              label={t('agendas_form_max_reservation_time_field_label', { ns: 'bookings' })}
              placeholder={t('agendas_form_max_reservation_time_field_placeholder', { ns: 'bookings' })}
              setFieldValue={setFieldValue}
              values={values}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="subtitle2">{t('required_deposit', { ns: 'bookings' })}</Typography>
              <Checkbox onChange={handleCheckRequestDeposit} checked={values.required_deposit} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomCurrencyInput
              inputType="number"
              field="required_deposit_amount"
              errors={errors}
              label={t('deposit_amount', { ns: 'bookings' })}
              setFieldValue={setFieldValue}
              values={values}
              required
              disabled={!values.required_deposit}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="subtitle2">{t('cleaning_fee', { ns: 'bookings' })}</Typography>
              <Checkbox onChange={handleCheckCleaningFee} checked={values.has_cleaning_fee} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CustomCurrencyInput
              inputType="number"
              field="cleaning_fee_amount"
              errors={errors}
              label={t('cleaning_fee_amount', { ns: 'bookings' })}
              setFieldValue={setFieldValue}
              values={values}
              required
              disabled={!values.has_cleaning_fee}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={6}>
            <ErrorHelper error={errorMessage} />
          </Grid>
          <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
            <LoadingButton
              loading={formIsSubmitting}
              variant="contained"
              size="small"
              type="submit"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              {t('form_button_submit_text', { ns: 'users' })}
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ActivitiesForm;
