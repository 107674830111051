import { LoadingButton } from '@mui/lab';
import { FormHelperText, Grid } from '@mui/material';
import { FormikContextType, FormikProvider, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import CustomInput from '@/src/components/forms/CustomInput';

const CategoriesForm = (props: Props) => {
  const { formik, isSubmitLoading, errorMessage, isEdit = false } = props;
  const { errors, values, setFieldValue } = formik;
  const { t } = useTranslation();

  return (
    <FormikProvider value={formik} data-testid="CategoriesForm-form">
      <Form>
        <Grid xs={12} container spacing={2} item sx={{ marginY: '1rem' }}>
          <Grid xs={12} sm={6} item>
            <CustomInput
              field="name"
              inputType="string"
              errors={errors}
              label={t('categories_form_name_field_label', { ns: 'categories' })}
              placeholder={t('categories_form_name_field_placeholder', { ns: 'categories' })}
              setFieldValue={setFieldValue}
              values={values}
              maxLength={50}
              required
            />
          </Grid>
          {errors.length && (
            <Grid xs={12} sm={6} item>
              <FormHelperText error={errorMessage !== null}>
                {isEdit ? t('error_message_in_edit_categorie_modal') : t('error_message_in_create_categorie_modal')}
              </FormHelperText>
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitLoading}
              variant="contained"
              size="small"
              type="submit"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              {t('form_button_submit_text', { ns: 'categories' })}
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

const propTypes = {};

interface ExtraProps {
  formik: FormikContextType<any>;
  isSubmitLoading: boolean;
  errorMessage: string;
  isEdit?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CategoriesForm.prototypes = propTypes;

export default CategoriesForm;
