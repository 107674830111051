import { Autocomplete, Box, FormHelperText, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { FormikErrors, FormikValues } from 'formik';
import { FormEvent } from 'react';
import CustomInput from '@/src/components/forms/CustomInput';
import CustomPasswordInput from '@/src/components/forms/CustomPasswordInput';
import CustomTextArea from '../../../../components/forms/text-area';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { Activities, AdminUsers } from '../../types';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const AdministratorForm = (props: Props) => {
  const {
    t,
    adminAlreadyExist,
    getUserData,
    adminUserGroups,
    amenitiesList,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    loading,
    errorMessage,
    isSubmitLoading,
    showAmenitiesSelector,
    showEmailSection,
  } = props;

  if (loading) {
    return (
      <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px' }}>
        <CircularSpinner />
      </Box>
    );
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {showEmailSection ? (
        <>
          <Grid container spacing={2} pt={1} direction={{ xs: 'column', sm: 'row' }}>
            <Grid item xs={12} mt="20px">
              <CustomInput
                field="email"
                errors={errors}
                label={t('user_form_email_field_label')}
                placeholder={t('user_form_email_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
            <LoadingButton
              loading={isSubmitLoading}
              variant="contained"
              size="small"
              type="button"
              onClick={getUserData}
              sx={{ width: { xs: '100%', sm: 'auto' }, mt: '25px' }}
            >
              {t('form_button_continue_text')}
            </LoadingButton>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2} pt={1} direction={{ xs: 'column', sm: 'row' }}>
            <Grid item md={6} sm={12} xs={12}>
              <CustomInput
                field="name"
                errors={errors}
                label={t('user_form_name_field_label', { ns: 'users' })}
                placeholder={t('user_form_name_field_placeholder', { ns: 'users' })}
                setFieldValue={setFieldValue}
                values={values}
                autoFocus
                maxLength={50}
                required
                disabled={adminAlreadyExist}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <CustomInput
                field="surname"
                errors={errors}
                label={t('user_form_surname_field_label', { ns: 'users' })}
                placeholder={t('user_form_surname_field_placeholder', { ns: 'users' })}
                setFieldValue={setFieldValue}
                values={values}
                maxLength={50}
                required
                disabled={adminAlreadyExist}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <CustomInput
                field="email"
                errors={errors}
                label={t('user_form_email_field_label', { ns: 'users' })}
                placeholder={t('user_form_email_field_placeholder', { ns: 'users' })}
                setFieldValue={setFieldValue}
                values={values}
                required
                disabled={adminAlreadyExist}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="flex-end">
              <PhoneSelect
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                isDisabled={adminAlreadyExist}
              />
            </Grid>
            {!adminAlreadyExist && (
              <Grid container item xs={12} spacing={2} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                <Grid item md={6} sm={12} xs={12}>
                  <CustomPasswordInput
                    field="password"
                    errors={errors}
                    label={t('user_form_password_field_label', { ns: 'users' })}
                    placeholder={t('user_form_password_field_placeholder', { ns: 'users' })}
                    setFieldValue={setFieldValue}
                    values={values}
                    required={!adminAlreadyExist}
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <CustomPasswordInput
                    field="confirmPassword"
                    errors={errors}
                    label={t('user_form_confirmPassword_field_label', { ns: 'users' })}
                    placeholder={t('user_form_confirmPassword_field_placeholder', { ns: 'users' })}
                    setFieldValue={setFieldValue}
                    values={values}
                    required={!adminAlreadyExist}
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6}>
              <Autocomplete
                multiple
                id="adminGroups"
                size="small"
                value={adminUserGroups?.filter((group) =>
                  values?.adminGroups?.some((g: AdminUsers) => g.id === group.id),
                )}
                getOptionLabel={(option) => option?.name}
                options={adminUserGroups ?? []}
                onChange={(e, value) => {
                  setFieldValue('adminGroups', value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('user_form_admin_groups_field_label', { ns: 'users' })} * `}
                    placeholder={t('user_form_admin_groups_field_placeholder', { ns: 'users' })}
                    error={!!errors.adminGroups}
                    helperText={errors.adminGroups as string}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <CustomTextArea
                id="notes"
                placeholder={t('user_form_notes_field_placeholder', { ns: 'users' })}
                minRows={3}
                style={{ width: '100%', height: '40px' }}
                value={values.notes}
                // maxLength={500}
                onChange={(e) => {
                  setFieldValue('notes', e.target.value);
                }}
              />
            </Grid>
            {showAmenitiesSelector && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="activityIds"
                  size="small"
                  value={amenitiesList?.activities?.filter((list) =>
                    values?.activityIds?.some((g: { id: number; description: string }) => g.id === list?.id),
                  )}
                  getOptionLabel={(option) => option.description}
                  options={amenitiesList?.activities ?? []}
                  onChange={(e, value) => {
                    setFieldValue('activityIds', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('user_form_amenities_list_field_label', { ns: 'users' })}
                      placeholder={t('user_form_amenities_list_field_placeholder', { ns: 'users' })}
                      error={!!errors.amenitiesList}
                      helperText={errors.amenitiesList?.toString()}
                    />
                  )}
                />
              </Grid>
            )}
            {errorMessage && (
              <Grid item xs={12} sm={8} md={6}>
                {errorMessage.code === 'ValueAlreadyExists' ? (
                  <FormHelperText error>
                    {t(`${errorMessage.code}${errorMessage.property_name}`, { ns: 'errors' })}
                  </FormHelperText>
                ) : (
                  <ErrorHelper error={errorMessage} />
                )}
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <LoadingButton
              loading={isSubmitLoading}
              variant="contained"
              size="small"
              type="submit"
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              {t('form_button_submit_text', { ns: 'users' })}
            </LoadingButton>
          </Grid>
        </>
      )}
    </Box>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  adminAlreadyExist: boolean;
  getUserData: (data: FormikValues) => Promise<void>;
  showEmailSection: boolean;
  adminUserGroups: Array<AdminUsers> | undefined | null;
  amenitiesList: { activities: Array<Activities> | undefined | null; totalCount: number };
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  loading: boolean;
  errorMessage: { code: string; message: string; property_name: string } | null;
  isSubmitLoading: boolean;
  showAmenitiesSelector: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
AdministratorForm.prototypes = propTypes;

export default AdministratorForm;
