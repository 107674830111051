import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventsPage from './EventsPage';
import { getEvents } from '@/src/api/endpoints/events';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import { Events } from './types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

export const EventsContainer = (props: Props) => {
  const queryClient = useQueryClient();
  const abortCont = new AbortController();

  const getEventsData = async (abort?: any) => {
    const response = await getEvents();
    return response.data;
  };

  const { data, isLoading, refetch } = useQuery(['events-publications'], getEventsData, {
    refetchOnReconnect: false,
  });

  const orderedData =
    data && data?.events?.sort((a: Events, b: Events) => (b?.creation_date || 0) - (a?.creation_date || 0));

  const [openModal, setOpenModal] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { SnackBar, setSnackBarMessage } = useSnackBar();
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 955;

  const [idSelected, setIdSelected] = useState<number | null>(null);

  useEffect(() => {
    if (refreshGrid) {
      queryClient.resetQueries(['events-publications']);
      refetch();
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  useEffect(() => {
    queryClient.resetQueries(['events-publications']);
    abortCont.abort();
  }, []);

  const handleClick = () => {
    setOpenModal(true);
  };

  const childProps = {
    isLoading,
    orderedData,
    idSelected,
    setRefreshGrid,
    handleClick,
    isMobile,
    data,
    setIdSelected,
    openModal,
    setOpenModal,
    SnackBar,
    setSnackBarMessage,
  };

  return <EventsPage {...childProps} />;
};

const propTypes = {};


interface ExtraProps {}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
EventsContainer.propTypes = propTypes;

export default EventsContainer;
