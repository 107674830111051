import { Box, Grid } from '@mui/material';
import { TFunction } from 'react-i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import St from './DocumentsDetail.styled';
import CustomLoader from '@/src/components/custom-loader';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import CustomDataTableForDocuments from './CustomDataTableForDocuments';
import { DocumentType } from '../types';

export const DocumentsForm = (props: Props) => {
  const { t, documentInfo, isLoadingGetDocumentInfo } = props;
  const lang = getLanguage()?.split('-')[0];

  return (
    <Box sx={{ mt: '5px' }}>
      {isLoadingGetDocumentInfo ? (
        <St.BoxLoader>
          <CustomLoader />
        </St.BoxLoader>
      ) : (
        <Grid container spacing={1} sx={{ mt: '5px' }}>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('documents_interested_description', { ns: 'documents' })}
              second={documentInfo?.description}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('documents_interested_creation_date', { ns: 'documents' })}
              second={getFormattedDateByLanguague(
                lang,
                getFormattedDateFullYearEs,
                getFormattedDateFullYearEn,
                documentInfo?.creation_date,
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('documents_interested_creation_user', { ns: 'documents' })}
              second={documentInfo?.creation_user}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('documents_interested_is_public', { ns: 'documents' })}
              second={documentInfo?.is_public ? `${t('documents_yes', { ns: 'documents' })}` : 'No'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoLabel
              first={t('documents_interested_category', { ns: 'documents' })}
              second={documentInfo?.category?.name}
            />
          </Grid>
          {documentInfo?.residencies.length > 0 && (
            <Grid item xs={12}>
              <CustomDataTableForDocuments
                title={t('documents_residencies', { ns: 'documents' })}
                data={documentInfo?.residencies}
                isLoading={isLoadingGetDocumentInfo}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

const propTypes = {};

interface ExtraProps {
  t: TFunction;
  documentInfo: DocumentType;
  isLoadingGetDocumentInfo: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
DocumentsForm.propTypes = propTypes;

export default DocumentsForm;
