import { AlertColor, Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import MarketingDetail from './marketing-living-detail';
import CustomLoader from '@/src/components/custom-loader';
import { BoxMarketing, MarketingHeader, MarketingPagesStylesDefault } from './MarketingLivingMaterials.styled';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import MarketingCard from '../marketing-card';
import RefreshButton from '@/src/components/refresh-button/RefreshButton';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import { Marketing } from '../types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import MarketingMaterialsModal from '../marketing-materials-modal';

export const MarketingMaterialsPage = (props: Props) => {
  const {
    isLoading,
    orderedData,
    idSelected,
    setRefreshGrid,
    handleClick,
    isMobile,
    data,
    setIdSelected,
    openModal,
    setOpenModal,
    SnackBar,
    setSnackBarMessage,
  } = props;

  const { t } = useTranslation();
  const resource = 'marketing_material';

  return (
    <Box sx={!isLoading && orderedData?.length < 1 ? MarketingPagesStylesDefault : { height: 'auto' }}>
      <MarketingHeader pr={idSelected ? '20px' : '10px'}>
        <BoxMarketing>
          <Typography variant="h5" component="h1">
            {t('marketing_materials_living', { ns: 'marketingMaterials' })}
          </Typography>
          <RefreshButton refresh={setRefreshGrid} disabled={isLoading} />
        </BoxMarketing>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          size="small"
          color="primary"
          sx={{ width: { sm: 'auto', xs: 'auto' } }}
          onClick={handleClick}
        >
          {t('new_publication', { ns: 'marketingMaterials' })}
        </Button>
      </MarketingHeader>
      <Grid container justifyContent="center">
        <Grid
          rowGap="20px"
          container
          item
          lg={idSelected ? 4 : 12}
          md={idSelected ? 4 : 12}
          sx={{
            maxHeight: `${isMobile ? 'auto' : '100%'}`,
            height: `${idSelected ? '850px' : '100%'}`,
            display: `${isMobile && idSelected && 'none'}`,
            overflow: 'auto',
            width: '100%',
          }}
        >
          {isLoading ? (
            <LoaderContainer>
              <CustomLoader />
            </LoaderContainer>
          ) : (
            ((!idSelected && isMobile) || !isMobile) &&
            data?.marketing_materials?.length > 0 &&
            orderedData?.map((item: Marketing) => (
              <Grid
                key={item.id}
                item
                lg={idSelected ? 12 : 4}
                md={idSelected ? 12 : 6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: '10px',
                }}
              >
                <MarketingCard item={item} setIdSelected={setIdSelected} />
              </Grid>
            ))
          )}
          {!isLoading && orderedData?.length < 1 && (
            <Box sx={{ margin: 'auto' }}>
              <AlertNoData title={t('no_data_was_found')} />
            </Box>
          )}
        </Grid>
        {idSelected && (
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <MarketingDetail
              idSelected={idSelected}
              setIdSelected={setIdSelected}
              setSnackBarMessage={setSnackBarMessage}
              resource={resource}
            />
          </Grid>
        )}
      </Grid>
      <MarketingMaterialsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setSnackBarMessage={setSnackBarMessage}
        groupName="living"
      />
      <SnackBar />
    </Box>
  );
};

const propTypes = {};

interface ExtraProps {
  isLoading: boolean;
  orderedData: any;
  idSelected: number | null;
  setRefreshGrid: (value: boolean) => void;
  handleClick: () => void;
  isMobile: boolean;
  data: any;
  setIdSelected: (value: number | null) => void;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  SnackBar: () => JSX.Element;
  setSnackBarMessage: (msj: string, sever?: AlertColor | undefined) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
MarketingMaterialsPage.propTypes = propTypes;

export default MarketingMaterialsPage;
