import { TFunction } from 'i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { DocumentType } from './types';
import { ActionHeader } from '../residents/residents-detail-section/types';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';

export const DocumentsPage = (props: Props) => {
  const { columns, actionColumns, headerActions, t } = props;

  return (
    <CustomDataTable
      title={t('documents')}
      columns={columns}
      resource="documents"
      pathApi="documents/v1/documents"
      listName="documents"
      canExport
      defaultSortFieldId="title"
      defaultSortAsc
      customActionColumns={actionColumns}
      extraActionsInHeader={headerActions}
    />
  );
};

const propTypes = {};

interface ExtraProps {
  columns: CustomDataTableColumnsProps<DocumentType>[];
  headerActions: ActionHeader[];
  actionColumns: ActionColumn[];
  t: TFunction;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
DocumentsPage.propTypes = propTypes;

export default DocumentsPage;
