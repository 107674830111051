import { Autocomplete, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import TableComponent from '../table-component';
import CustomTabPanel from '../custom-tab-panel';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { Users } from '../../../types';
import { TLanguagesOptions } from '@/src/utilities/helpers/commonTypes';

const NewsFormAddressee = (props: Props) => {
  const {
    t,
    tabValue,
    values,
    setFieldValue,
    DeselectUserForTable,
    groupList,
    errors,
    SelectGroupForTable,
    usersStillAvailableList,
    SelectUserForTable,
    setGroupList,
  } = props;

  return (
    <CustomTabPanel value={tabValue} index={1}>
      <Grid item xs={12} sm={12} md={12}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={values?.userListTargetType}
            name="radio-buttons-group"
          >
            <Grid item xs={12} sm={12} md={12}>
              <FormControlLabel
                value="group"
                control={<Radio />}
                onChange={() => {
                  setFieldValue('userListTargetType', 'group');
                  DeselectUserForTable('group');
                }}
                label={`${t('news_form_for_groups_field_placeholder', { ns: 'news' })}`}
              />
              <FormControlLabel
                value="user"
                control={<Radio />}
                onChange={() => {
                  setFieldValue('userListTargetType', 'user');
                  DeselectUserForTable('user');
                }}
                label={`${t('news_form_for_user_field_placeholder', { ns: 'news' })}`}
              />
            </Grid>
          </RadioGroup>
        </FormControl>

        {values?.userListTargetType === 'group' ? (
          <Grid container spacing={1} sx={{ mt: '5px' }}>
            <Grid item xs={12} sm={10} md={10}>
              <Autocomplete
                id="selectedUser"
                size="small"
                value={values?.selectedUser || null}
                getOptionLabel={(option) => option.description}
                options={groupList ?? []}
                filterSelectedOptions
                onChange={(e, value) => {
                  setFieldValue('selectedUser', value ?? '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('news_form_user_groups_field_label', { ns: 'news' })} * `}
                    placeholder={t('news_form_user_groups_field_placeholder', { ns: 'news' })}
                    error={errors.adminGroups !== undefined}
                    helperText={errors.adminGroups?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Button
                variant="outlined"
                color="success"
                size="small"
                type="button"
                onClick={SelectGroupForTable}
                sx={{ height: { xs: '100%', sm: '100%' }, width: '100%', fontSize: '10px' }}
              >
                {t('news_form_select', { ns: 'news' })}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1} sx={{ mt: '5px' }}>
            <Grid item xs={12} sm={10} md={10}>
              <Autocomplete
                id="selectedUser"
                size="small"
                value={values?.selectedUser || null}
                getOptionLabel={(option) => option.name}
                options={usersStillAvailableList ?? []}
                filterSelectedOptions
                onChange={(e, value) => {
                  setFieldValue('selectedUser', value ?? '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('news_form_user_groups_field_label', { ns: 'news' })} * `}
                    placeholder={t('news_form_user_groups_field_placeholder', { ns: 'news' })}
                    error={errors.adminGroups !== undefined}
                    helperText={errors.adminGroups?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Button
                variant="outlined"
                color="success"
                size="small"
                type="button"
                onClick={SelectUserForTable}
                sx={{ height: { xs: '100%', sm: '100%' }, width: '100%', fontSize: '10px' }}
              >
                {t('news_form_select', { ns: 'news' })}
              </Button>
            </Grid>
          </Grid>
        )}
        {values?.userSelectedList.length > 0 && (
          <Grid item xs={12} sm={12} md={12} sx={{ pt: '15px' }}>
            <TableComponent
              rows={values?.userSelectedList}
              setFieldValue={setFieldValue}
              usersList={values?.userListTargetType === 'group' ? groupList : usersStillAvailableList}
              setUsersList={setGroupList}
            />
          </Grid>
        )}
      </Grid>
    </CustomTabPanel>
  );
};

const propTypes = {};



interface ExtraProps {
  t: (par1: string, par2?: TLanguagesOptions) => string;
  tabValue: number;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  DeselectUserForTable: (type: string) => void;
  groupList: Array<Users> | null | undefined;
  errors: FormikErrors<FormikValues>;
  SelectGroupForTable: () => void;
  usersStillAvailableList: any;
  SelectUserForTable: () => void;
  setGroupList: (object: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
NewsFormAddressee.propTypes = propTypes;

export default NewsFormAddressee;
