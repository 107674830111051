import { FormikErrors, FormikValues } from 'formik';
import { useState } from 'react';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import NewsFormInformation from './NewsFormInformation';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';

const NewsFormInformationContainer = (props: Props) => {
  const { tabValue, values, errors, setFieldValue, setFieldError, errorMessage, isLoadingSubmit, setDisableButton } =
    props;

  const deleteImage = (value: any, url?: string) => {
    setFieldValue(
      value,
      Array.isArray(values[`${value}`]) ? values?.[`${value}`].filter((element: any) => element.image_url !== url) : '',
    );
  };

  const attachedImagesLength = values?.publication_image_urls.length;

  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'cover_image_url',
    setFieldValue,
    setDisableForm: setDisableButton,
  });
  const { ImageUploader: AttachedImagesUploader, wrongImages: wrongAttachedImages } = useImageUploader({
    htmlFor: 'attached-images-urls',
    values,
    valueName: 'publication_image_urls',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const childProps = {
    tabValue,
    values,
    deleteImage,
    ImageUploader,
    wrongCoverImages,
    errors,
    setFieldValue,
    setDisableButton,
    setFieldError,
    AttachedImagesUploader,
    attachedImagesLength,
    errorMessage,
    wrongAttachedImages,
    isLoadingSubmit,
  };

  return <NewsFormInformation {...childProps} />;
};

const propTypes = {};



interface ExtraProps {
  tabValue: number;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  setFieldError: (field: string, value: string | undefined) => void;
  errorMessage: string | null;
  isLoadingSubmit: boolean;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
NewsFormInformationContainer.propTypes = propTypes;

export default NewsFormInformationContainer;
