import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import ConstructionCard from './ConstructionCard';
import { Construction } from '../types';

const ConstructionCardContainer = (props: Props) => {
  const { item, setIdSelected } = props;

  const textReload = (text: string) => {
    const titleReload = text.length > 45 ? `${text.slice(0, 45)}...` : text;
    return titleReload;
  };

  const { id, title, creation_date: creationDate, cover_image_url: coverImageUrl, subtitle } = item;
  const handleClick = (event: any, selectedId: any) => {
    setIdSelected(selectedId);
  };

  const childProps = { ...props, textReload, id, item, title, creationDate, coverImageUrl, subtitle, handleClick };

  return <ConstructionCard {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  item: Construction;
  setIdSelected: (id: number) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
ConstructionCardContainer.propTypes = propTypes;

export default ConstructionCardContainer;
