import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { Buildingvalues, Tower } from '@/src/pages/building-swap/types';

export const BuildingSelector = (props: Props) => {
  const {
    t,
    values,
    firstTowersGroup,
    secondTowersGroup,
    thirdTowersGroup,
    handleFirstTowerChange,
    handleSecondTowerChange,
    handleThirdTowerChange,
  } = props;

  return (
    <>
      {!firstTowersGroup ? (
        <CircularSpinner />
      ) : (
        <Autocomplete
          id="first-tower"
          size="small"
          value={firstTowersGroup?.find((tower) => values.firstTowerInstance?.id === tower.id) || null}
          getOptionLabel={(option) => option.name}
          options={firstTowersGroup || []}
          onChange={handleFirstTowerChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: '100%' }}
              variant="outlined"
              label={t('tower')}
              placeholder={t('tower_autocomplete_placeholder')}
            />
          )}
        />
      )}
      {!secondTowersGroup && values?.firstTowerInstance?.has_children && <CircularSpinner />}
      {values.firstTowerInstance?.has_children && secondTowersGroup && (
        <Autocomplete
          id="second-tower"
          sx={{ width: '100%', paddingTop: '20px' }}
          size="small"
          value={secondTowersGroup?.find((tower) => values.secondTowerInstance?.id === tower.id) || null}
          getOptionLabel={(option) => option.name}
          options={secondTowersGroup || []}
          onChange={handleSecondTowerChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: '100%' }}
              variant="outlined"
              label={t('tower')}
              placeholder={t('tower_autocomplete_placeholder')}
            />
          )}
        />
      )}

      {values.secondTowerInstance?.has_children && thirdTowersGroup && (
        <Autocomplete
          id="third-tower"
          sx={{ width: '100%', paddingTop: '20px' }}
          size="small"
          value={thirdTowersGroup?.find((tower) => values.thirdTowerInstance?.id === tower.id) || null}
          getOptionLabel={(option) => option.name}
          options={thirdTowersGroup || []}
          onChange={handleThirdTowerChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: '100%' }}
              variant="outlined"
              label={t('tower')}
              placeholder={t('tower_autocomplete_placeholder')}
            />
          )}
        />
      )}
    </>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  values: Buildingvalues;
  firstTowersGroup: Tower[] | null;
  setFirstTowersGroup?: React.Dispatch<React.SetStateAction<Tower[]>> | null;
  secondTowersGroup: Tower[] | null;
  thirdTowersGroup: Tower[] | null;
  handleFirstTowerChange: (event: any, value: any) => void;
  handleSecondTowerChange: (event: any, value: any) => void;
  handleThirdTowerChange: (event: any, value: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}

BuildingSelector.propTypes = propTypes;

export default BuildingSelector;
