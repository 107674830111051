import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormikValues, useFormik } from 'formik';
import EditPerson from './EditPerson';
import { PropCreateModal } from '@/src/hooks/common/useDialog';
import { ResidentDetail } from '../../types';
import { editPersonInformation } from '@/src/api/endpoints/residents';
import { validate } from './EditPerson.validate';
import { getCities, getCountries, getStates } from '@/src/api/endpoints/master';
import { useGenerateImageUrl } from '@/src/hooks/common/useGenerateImageUrl';

const EditPersonContainer = (props: EditPersonContainerProps) => {
  const { t } = useTranslation('residents');
  const { setSnackBarMessage, close, person } = props;
  const { getUrl, isLoadingImage, errorSubmittingImage, url } = useGenerateImageUrl();
  const queryClient = useQueryClient();

  React.useEffect(
    () => () => {
      queryClient.resetQueries(['getCountries']);
      queryClient.resetQueries(['getStates']);
      queryClient.resetQueries(['getCities']);
    },
    [],
  );

  const { mutate, isLoading: isLoadingsubmit } = useMutation(editPersonInformation, {
    onError: async (e: any) => {
      setSnackBarMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_user_edition_snackbar_text', { ns: 'users' }));
    },
  });

  const getInitialValues = () => ({
    city: person?.city?.id ? person?.city : null,
    state: person?.state?.id ? person?.state : null,
    country: person?.country?.id ? person.country : null,
    name: person?.name ?? '',
    surname: person?.surname ?? '',
    alias: person?.alias ?? '',
    email: person?.email ?? '',
    birthday: person?.birthday ?? null,
    countryCode: person?.countryCode ?? '',
    countryIsoCode: person?.countryIsoCode ?? '',
    phoneNumber: person?.phoneNumber ?? '',
    streetName: person?.streetName ?? '',
    streetNumber: person?.streetNumber ?? '',
    apartmentNumber: person?.apartmentNumber ?? '',
    floorNumber: person?.floorNumber ?? '',
    zipCode: person?.zipCode ?? '',
    identificationNumber: person?.identificationNumber ?? '',
    // identificationFileUrl: person?.identificationFileUrl ?? '', // foto pasaporte
  });

  const onSubmit = (values: FormikValues) => {
    const reqValues = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      country_code: values.countryCode,
      country_iso_code: values.countryIsoCode,
      phone_number: values.phoneNumber,
      birthday: values.birthday,
      city_id: values.city.id,
      identification_number: values.identificationNumber,
      // identification_file_url: values.identificationFileUrl,
      street_name: values.streetName,
      street_number: values.streetNumber,
      apartment_number: values.apartmentNumber,
      floor_number: values.floorNumber,
      zip_code: values.zipCode,
      alias: values.alias,
    };
    mutate({
      id: Number(person?.id),
      values: reqValues,
    });
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validationSchema: validate({ t }),
    enableReinitialize: true,
    validateOnChange: false,
  });

  const { data: countriesData, isLoading: loadingCountriesData } = useQuery(['getCountries'], () => getCountries(), {
    select: (data) => data?.data?.countries as Array<{ id: number; name: string }>,
  });
  const { data: statesData } = useQuery(
    ['getStates', formik?.values?.country?.id],
    () => getStates(Number(formik.values?.country?.id)),
    {
      select: (data) => data?.data?.states as Array<{ id: number; name: string }>,
      enabled: !!formik?.values?.country?.id,
    },
  );
  const { data: citiesData } = useQuery(
    ['getCities', formik.values?.state?.id],
    () => getCities(Number(formik.values?.state?.id)),
    {
      select: (data) => data?.data?.cities as Array<{ id: number; name: string }>,
      enabled: !!formik.values.state?.id,
    },
  );

  // React.useEffect(() => {
  //   if (url) {
  //     formik.setFieldValue('identificationFileUrl', url as string);
  //   }
  // }, [url]);

  const personFields = ['name', 'surname', 'alias', 'email'];
  const addressFields = [
    { code: 'street_name', name: 'streetName', type: 'text', size: 'default' },
    { code: 'street_number', name: 'streetNumber', type: 'number', size: 'small' },
    { code: 'floor_number', name: 'floorNumber', type: 'number', size: 'small' },
    { code: 'apartment_number', name: 'apartmentNumber', type: 'text', size: 'small' },
    { code: 'zip_code', name: 'zipCode', type: 'number', size: 'small' },
  ];

  const childProps = {
    ...props,
    t,
    isLoading: loadingCountriesData,
    isLoadingsubmit,
    formik,
    personFields,
    addressFields,
    countriesData,
    statesData,
    citiesData,
    getUrlIn64: getUrl,
    isLoadingImage,
    errorSubmittingImage,
    url,
  };
  return <EditPerson {...childProps} />;
};

interface EditPersonContainerProps {
  person: ResidentDetail | undefined;
  Modal: React.FunctionComponent<PropCreateModal>;
  close: () => void;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

export default EditPersonContainer;
