import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { AlertColor } from '@mui/material';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import MarketingDetail from './MarketingLivingDetail';
import { useGetMarketingById } from '../fetchHelperMarketing';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';
import { deleteMarketingMaterial } from '@/src/api/endpoints/marketingMaterials';

const MarketingDetailContainer = (props: Props) => {
  const { idSelected, setIdSelected, setSnackBarMessage, resource } = props;
  const queryClient = useQueryClient();
  const [openEditInformation, setOpenEditInformation] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation();
  const { dataById, isLoading, refetch } = useGetMarketingById(idSelected);
  const cleanHTML = DOMPurify.sanitize(dataById?.marketing_material?.body, {
    USE_PROFILES: { html: true },
  });

  const { mutate: deleteMutation, status } = useMutation(deleteMarketingMaterial, {
    onError: async () => {
      setSnackBarMessage(t('uncatch_error', { ns: 'errors' }), 'error');
    },
    onSuccess: async () => {
      queryClient.resetQueries(['publications']);
      setIdSelected(null);
      setSnackBarMessage(t('successful_publication_deletion_snackbar_text', { ns: 'marketingMaterials' }));
    },
  });

  const onCloseArticleSelected = () => {
    setIdSelected(null);
  };

  const childProps = {
    ...props,
    isLoading,
    dataById,
    setOpenEditInformation,
    setDeleteModal,
    deleteModal,
    deleteMutation,
    onCloseArticleSelected,
    cleanHTML,
    openEditInformation,
    refetch,
    setSnackBarMessage,
    resource,
  };

  return <MarketingDetail {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  idSelected: number;
  setIdSelected: (id: number | null) => void;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
  resource: string;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
MarketingDetailContainer.propTypes = propTypes;

export default MarketingDetailContainer;
