import { TFunction } from 'i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { Interested } from './types';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';

export const PotentialCustomerPage = (props: Props) => {
  const { columns, actionColumns, t } = props;

  return (
    <CustomDataTable
      title={t('potential_customers')}
      columns={columns}
      resource="potential_customers"
      pathApi="accounts/v1/potential-customers"
      listName="potential_customers"
      canExport
      defaultSortFieldId="name"
      defaultSortAsc
      // extraActionsInHeader={headerActions}
      customActionColumns={actionColumns}
    />
  );
};

const propTypes = {};



interface ExtraProps {
  columns: CustomDataTableColumnsProps<Interested>[];
  actionColumns: ActionColumn[];
  // headerActions: ActionHeader[];
  t: TFunction;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PotentialCustomerPage.propTypes = propTypes;

export default PotentialCustomerPage;
