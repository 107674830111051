import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MarketingMaterialsPage from './MarketingLivingMaterialsPage';
import { getMarketingMaterials } from '@/src/api/endpoints/marketingMaterials';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import { Marketing } from '../types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

export const MarketingMaterialsPageContainer = (props: Props) => {
  const queryClient = useQueryClient();
  const abortCont = new AbortController();
  const getMarketingData = async (abort?: any) => {
    const response = await getMarketingMaterials('living');
    return response.data;
  };

  const { data, isLoading, refetch } = useQuery(['publications'], getMarketingData, {
    refetchOnReconnect: false,
  });

  const orderedData =
    data &&
    data?.marketing_materials?.sort((a: Marketing, b: Marketing) => (b?.creation_date || 0) - (a?.creation_date || 0));

  const [openModal, setOpenModal] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { SnackBar, setSnackBarMessage } = useSnackBar();
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 955;

  const [idSelected, setIdSelected] = useState<number | null>(null);

  useEffect(() => {
    if (refreshGrid) {
      queryClient.resetQueries(['publications']);
      refetch();
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  useEffect(() => {
    queryClient.resetQueries(['publications']);
    abortCont.abort();
  }, []);

  const handleClick = () => {
    setOpenModal(true);
  };

  const childProps = {
    isLoading,
    orderedData,
    idSelected,
    setRefreshGrid,
    handleClick,
    isMobile,
    data,
    setIdSelected,
    openModal,
    setOpenModal,
    SnackBar,
    setSnackBarMessage,
  };

  return <MarketingMaterialsPage {...childProps} />;
};

const propTypes = {};

interface ExtraProps {}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
MarketingMaterialsPageContainer.propTypes = propTypes;

export default MarketingMaterialsPageContainer;
