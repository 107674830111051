import { AlertColor, Box, Button, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import React from 'react';
import { TFunction } from 'react-i18next';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import RefreshButton from '@/src/components/refresh-button/RefreshButton';
import { PropCreateModal } from '@/src/hooks/common/useDialog';
import {
  getFormattedDateByLanguague,
  getFormattedDateNumberEn,
  getFormattedDateNumberEs,
} from '@/src/utilities/helpers/dateParser';
import { ResidentDetail } from '../types';
import EditPerson from './edit-person';

const ResidentCard = (props: ResidentCardProps) => {
  const { isLoading, resident, t, refetch, Modal, close, isOpen, open, setSnackBarMessage, lang } = props;

  return (
    <Card>
      <CardHeader
        title={t('resident_card_title')}
        action={
          <Box display="flex" alignItems="center">
            <Button 
              variant="contained" 
              color="primary" 
              onClick={open} 
              sx={{ marginRight: '8px' }}
            >
              {t('edit_resident')}
            </Button>
            <RefreshButton refresh={refetch} disabled={isLoading} />
          </Box>
        }
      />
      <Divider />
      <CardContent>
        {isLoading ? (
          <CircularSpinner />
        ) : (
          <Box component="div">
            <InfoLabel first={t('resident_name')} second={`${resident?.name} ${resident?.surname}`} />
            <InfoLabel first={t('resident_alias')} second={resident?.alias ?? '-'} />
            <InfoLabel
              first={t('resident_birthday')}
              second={
                resident?.birthday
                  ? getFormattedDateByLanguague(
                      lang,
                      getFormattedDateNumberEn,
                      getFormattedDateNumberEs,
                      resident?.birthday,
                    )
                  : '-'
              }
            />
            <InfoLabel
              first={t('phone_number', { ns: 'common' })}
              second={`${resident?.countryCode} ${resident?.phoneNumber}`}
            />
            <InfoLabel
              first={t('residency')}
              second={
                resident?.from && resident?.to
                  ? `${getFormattedDateByLanguague(
                      lang,
                      getFormattedDateNumberEn,
                      getFormattedDateNumberEs,
                      resident?.from,
                    )} - ${getFormattedDateByLanguague(
                      lang,
                      getFormattedDateNumberEn,
                      getFormattedDateNumberEs,
                      resident?.to,
                    )}`
                  : '-'
              }
            />
            <Typography fontSize="16px" fontWeight="600" marginTop="10px">
              {t('address_info')}
            </Typography>
            <Divider />
            <InfoLabel
              first={t('resident_address')}
              second={`${resident?.streetName ?? ''} ${resident?.streetNumber ?? '-'}`}
            />
            <Box display="flex" justifyContent="space-between">
              {resident?.floorNumber && <InfoLabel first={t('resident_floor')} second={resident?.floorNumber} />}
              {resident?.apartmentNumber && (
                <InfoLabel first={t('resident_apartment')} second={resident?.apartmentNumber} />
              )}
            </Box>
            <InfoLabel first={t('resident_city')} second={resident?.city?.name ?? '-'} />
            <InfoLabel first={t('resident_state')} second={resident?.state?.name ?? '-'} />
            <InfoLabel first={t('resident_country')} second={resident?.country?.name ?? '-'} />
            <InfoLabel first={t('resident_zip_code')} second={resident?.zipCode ?? '-'} />
          </Box>
        )}
      </CardContent>
      {isOpen && <EditPerson setSnackBarMessage={setSnackBarMessage} Modal={Modal} close={close} person={resident} />}
    </Card>
  );
};

interface ResidentCardProps {
  resident?: ResidentDetail;
  isLoading: boolean;
  t: TFunction;
  refetch: any;
  Modal: React.FunctionComponent<PropCreateModal>;
  close: () => void;
  isOpen: boolean;
  open: () => void;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
  lang: string;
}

export default ResidentCard;
