import i18n from 'i18next';
import axios, { AxiosResponse } from 'axios';
import { getsLanguages } from '@/src/api/endpoints/master';
import { GetChildrenResponse, GetMenusResponse } from '@/src/hooks/auth/types';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';

export const isAuthenticated = () => localStorage.getItem('tokenId');

export const handleLanguageOnLogin = async (userData: any) => {
  const { data: languageData } = await getsLanguages();

  if (languageData) {
    const selectedLanguage = languageData.languages.find(
      (language: { id: number; code: string }) => language.id === userData.user.language_id,
    );
    i18n.changeLanguage(selectedLanguage.code.split('-')[0]);
  } else {
    i18n.changeLanguage(i18n.language.split('-')[0]);
  }
};

export const getMainTowerChildrens = async (access_token: string) => {
  const { VITE_TENANT_ID, VITE_TOKEN_TYPE, VITE_API_URL } = getEnvVariables();

  const childrenResponse: AxiosResponse<GetChildrenResponse> = await axios.get(
    `${VITE_API_URL}/master/v1/clients/${VITE_TENANT_ID}/children`,
    {
      headers: {
        Authorization: `${VITE_TOKEN_TYPE} ${access_token}`,
        'x-tenant-id': VITE_TENANT_ID,
      },
    },
  );

  return childrenResponse?.data?.client;
};

export const getMenusOnLogin = async (access_token: string, tenantId: string) => {
  const { VITE_TOKEN_TYPE, VITE_API_URL } = getEnvVariables();

  const menusResponse: AxiosResponse<GetMenusResponse> = await axios.get(`${VITE_API_URL}/administration/v1/menus`, {
    headers: {
      Authorization: `${VITE_TOKEN_TYPE} ${access_token}`,
      'x-tenant-id': tenantId,
    },
  });
  return menusResponse.data.menus;
};
