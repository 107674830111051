import { FormikErrors, FormikValues } from 'formik';
import { AlertColor, Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { SubmitButton } from './DocumentsForm.styled';
import CustomInput from '@/src/components/forms/CustomInput';
import PdfFileUploader from '@/src/components/pdf-file-uploader/PdfFileUploader';
import { tCommon } from '@/src/utilities/helpers/appConstants';

export const DocumentsForm = (props: Props) => {
  const {
    t,
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    errors,
    errorMessage,
    setSnackBarMessage,
    DocumentTypeList,
    properties,
  } = props;

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5px' }}>
      <Grid container spacing={1} sx={{ mt: '5px' }}>
        <Grid item xs={12} sm={4} md={4}>
          <CustomInput
            field="description"
            noError
            label={t('description', tCommon)}
            placeholder={t('documents_enter_description', { ns: 'documents' })}
            setFieldValue={setFieldValue}
            values={values}
            autoFocus
            maxLength={100}
            required
          />
          <ErrorText error={errors.title as string} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Autocomplete
            id="document_type"
            size="small"
            value={values?.document_category_id || null}
            getOptionLabel={(option) => option?.name}
            options={DocumentTypeList?.categories ?? []}
            onChange={(e: any, value: any) => {
              setFieldValue('document_category_id', value ?? '');
            }}
            filterSelectedOptions
            sx={{ width: '100%' }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                sx={{ width: '100%' }}
                variant="outlined"
                label={t('documents_type_list', { ns: 'documents' })}
                placeholder={t('documents_type_list_autocomplete_placeholder', { ns: 'documents' })}
                error={errors?.document_type !== undefined}
                helperText={errors?.document_type?.toString()}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Autocomplete
            multiple
            id="residency_ids"
            size="small"
            value={values?.residency_ids || null}
            getOptionLabel={(option) => option}
            options={properties ?? []}
            onChange={(e, value) => {
              setFieldValue('residency_ids', value ?? '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t('documents_owners_list', { ns: 'documents' })}`}
                placeholder={t('documents_owners_list_autocomplete_placeholder', { ns: 'documents' })}
                error={errors.functional_unit !== undefined}
                helperText={errors.functional_unit?.toString()}
              />
            )}
          />
        </Grid>
        <Grid container spacing={1} sx={{ mt: '5px' }}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="subtitle2">{t('documents_file', { ns: 'documents' })}</Typography>
            <PdfFileUploader values={values} setFieldValue={setFieldValue} setError={setFieldError} />
            <ErrorText error={errors.document as string} />
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid xs={6} item>
            <ErrorText error={errorMessage} />
          </Grid>
        )}
        <Grid container item xs={errorMessage ? 6 : 12} justifyContent="flex-end">
          <SubmitButton variant="contained" type="submit">
            {t('post', { ns: 'documents' })}
          </SubmitButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const propTypes = {};


interface ExtraProps {
  t: TFunction;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  setFieldError: (field: string, value: string | undefined) => void;
  errors: FormikErrors<FormikValues>;
  errorMessage: string | null;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
  DocumentTypeList: any;
  properties: any;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
DocumentsForm.propTypes = propTypes;

export default DocumentsForm;
