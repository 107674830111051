import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { FormikErrors, FormikProvider, FormikValues, Form, FormikContextType } from 'formik';
import { TFunction } from 'i18next';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { SubmitButton } from './PotentialCustomerForm.styled';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import ResidencyTypesAutocomplete from '@/src/components/residency-types-autocomplete';
import CustomDateTimePicker from '@/src/components/date-time-picker';
import CustomCurrencyInput from '@/src/components/forms/CustomCurrencyInput';
import { RelationshipType, State } from '../types';

const PotentialCustomerForm = (props: Props) => {
  const {
    minValue,
    isLoadingSubmit,
    errorMessage,
    disableButton,
    setDisableButton,
    t,
    properties,
    getTomorrow,
    handleCheck,
    countriesData,
    statesData,
    citiesData,
    formik,
    close,
  } = props;

  const { errors, values, setFieldValue } = formik;

  return (
    <FormikProvider value={formik} data-testid="CategoriesForm-form">
      <Form>
        <Grid container spacing={1} sx={{ mt: '10px' }}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="country"
              size="small"
              options={countriesData ?? []}
              onChange={(e: any, value: any) => {
                if (!value) {
                  setFieldValue('state', null);
                  setFieldValue('city', null);
                }
                setFieldValue('country', value);
              }}
              value={countriesData?.find((country: any) => String(country.id) === String(values?.country?.id)) || null}
              getOptionLabel={(option) => option.name}
              renderOption={(propss, option) => (
                <Box component="li" key={option.id} {...propss}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`${t('interested_country_field_label', { ns: 'interested' })} *`}
                  placeholder={t('interested_country_field_label_placeholder', { ns: 'interested' })}
                  error={errors?.country !== undefined}
                  helperText={errors?.country?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="state"
              size="small"
              options={statesData ?? []}
              onChange={(e: any, value: any) => {
                if (!value) {
                  setFieldValue('city', null);
                }
                setFieldValue('state', value);
              }}
              value={statesData?.find((state: any) => String(state.id) === String(values?.state?.id)) || null}
              getOptionLabel={(option) => option.name}
              disabled={!values.country}
              renderOption={(propss, option) => (
                <Box component="li" key={option.id} {...propss}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`${t('interested_state_field_label', { ns: 'interested' })} *`}
                  placeholder={t('interested_state_field_label_placeholder', { ns: 'interested' })}
                  error={errors?.states !== undefined}
                  helperText={errors?.states?.toString()}
                  disabled={!values.country}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="city"
              size="small"
              options={citiesData ?? []}
              onChange={(e: any, value: any) => {
                setFieldValue('city', value);
              }}
              value={citiesData?.find((city: any) => String(city.id) === String(values?.city?.id)) || null}
              getOptionLabel={(option) => option.name}
              disabled={!values.state}
              renderOption={(propss, option) => (
                <Box component="li" key={option.id} {...propss}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`${t('interested_city_field_label', { ns: 'interested' })} *`}
                  placeholder={t('interested_city_field_label_placeholder', { ns: 'interested' })}
                  error={errors?.cityId !== undefined}
                  helperText={errors?.city?.toString()}
                  disabled={!values.state}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ResidencyTypesAutocomplete
              onChange={(e: any, value: any) => {
                setFieldValue('residency_type', value);
              }}
              value={values.residencyType?.code ?? null}
            />
          </Grid>
          <Grid xs={12} item alignItems="baseline" gap={0.5}>
            <Typography component="h6" sx={{ fontWeight: 'bolder' }}>
              {`${t('interested_residency', { ns: 'interested' })}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Autocomplete
              id="property_code"
              size="small"
              value={values?.apartment || null}
              getOptionLabel={(option) => option}
              options={properties ?? []}
              onChange={(e, value) => {
                setFieldValue('property_code', value ?? '');
              }}
              sx={{ backgroundColor: '' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`${t('interested_room_field_label', { ns: 'interested' })}`}
                  placeholder={t('interested_room_field_placeholder', { ns: 'interested' })}
                  error={errors?.apartment !== undefined}
                  helperText={errors?.apartment?.toString()}
                  sx={{
                    input: {
                      WebkitTextFillColor: '',
                    },
                    label: {
                      color: '',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <CustomDateTimePicker
              value={values?.from}
              minDate={minValue}
              onChange={(newValue: any): void => {
                setFieldValue('from', new Date(newValue.$d).getTime());
              }}
              fieldValue="from"
              errorMessage={errors?.from as string}
              required
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <CustomDateTimePicker
              value={values?.to}
              onChange={(newValue: any): void => {
                setFieldValue('to', new Date(newValue.$d).getTime());
              }}
              minDate={getTomorrow(values?.from)}
              fieldValue="to"
              errorMessage={errors?.to as string}
              required
            />
          </Grid>
          <Grid xs={12} md={12} lg={4} item>
            <FormControlLabel
              onChange={handleCheck}
              control={<Checkbox checked={values?.has_deposit} />}
              label={`${t('interested_has_amount', { ns: 'interested' })}`}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item mt="2px">
            <CustomCurrencyInput
              field="deposit_amount"
              value={values?.deposit_amount}
              label={t('interested_deposit_amount', { ns: 'interested' })}
              setFieldValue={setFieldValue}
              disabled={!values?.has_deposit}
            />
          </Grid>
          {errorMessage && (
            <Grid xs={6} item>
              <ErrorText error={errorMessage} />
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="flex-end" gap="10px">
            <Button variant="outlined" color="secondary" onClick={close}>
              {t('interested_cancel_button', { ns: 'interested' }) as string}
            </Button>
            <SubmitButton loading={isLoadingSubmit} variant="contained" type="submit" disabled={disableButton}>
              {t('interested_accept_button', { ns: 'interested' }) as string}
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

const propTypes = {};



interface ExtraProps {
  minValue: number;
  isLoadingSubmit: boolean;
  errorMessage: string | null;
  disableButton: boolean;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  properties: string[] | undefined;
  getTomorrow: (value: number) => number;
  handleCheck: () => void;
  countriesData: RelationshipType[];
  statesData: State[];
  citiesData: RelationshipType[];
  formik: FormikContextType<any>;
  close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PotentialCustomerForm.propTypes = propTypes;

export default React.memo(PotentialCustomerForm);
