import { useState } from 'react';
import { AlertColor } from '@mui/material';
import { ArticleByIdBody } from '../types';
import PerksBenefitsModal from './PerksBenefitsModal';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

export const PerksBenefitsModalContainer = (props: Props) => {
  const { dataById, openModal, refetchDataById, setOpenModal, setSnackBarMessage } = props;

  const [closeCreateModalConfirmation, setCreateModalConfirmation] = useState(false);
  const [openCreateModalCloseConfirmation, setOpenCreateModalCloseConfirmation] = useState(false);

  const handleClose = () => {
    if (!closeCreateModalConfirmation) {
      setOpenCreateModalCloseConfirmation(true);
      return;
    }
    setOpenModal(false);
  };

  const handleCloseConfirmation = () => {
    setOpenModal(false);
    setCreateModalConfirmation(false);
    setOpenCreateModalCloseConfirmation(false);
  };

  const handleBackToCreating = () => {
    setOpenCreateModalCloseConfirmation(false);
  };

  const childProps = {
    ...props,
    dataById,
    handleClose,
    handleCloseConfirmation,
    openCreateModalCloseConfirmation,
    handleBackToCreating,
  };

  return <PerksBenefitsModal {...childProps} />;
};

const propTypes = {};



interface ExtraProps {
  dataById?: ArticleByIdBody;
  openModal: boolean;
  refetchDataById?: () => void;
  setOpenModal: (x: boolean) => void;
  setSnackBarMessage: (sms: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PerksBenefitsModalContainer.propTypes = propTypes;

export default PerksBenefitsModalContainer;
