import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { useContext } from 'react';
import MontserratThin from '../../assets/fonts/Montserrat/Montserrat-Thin.ttf';
import MontserratMedium from '../../assets/fonts/Montserrat/Montserrat-Medium.ttf';
import MontserratBold from '../../assets/fonts/Montserrat/Montserrat-Bold.ttf';
import MontserratExtraBold from '../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf';
import { AuthContext } from '@/src/context/auth.context';

const useBaseBirgeandheldTheme = () => {
  const { clientStyle } = useContext(AuthContext);

  const baseTheme = createTheme({
    palette: {
      error: {
        main: red.A400,
      },
      mode: 'light',
      primary: {
        main: JSON.parse(clientStyle)?.primary_color ?? '#006EA9',
        light: '#7CABC5',
      },
      secondary: {
        main: JSON.parse(clientStyle)?.secondary_color ?? '#948A7C',
        light: '#C3C1BD',
      },
      background: {
        default: '#F0F0F0',
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none',
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: '#10748B',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
              @font-face {
                font-family: "Montserrat";
                font-style: thin;
                font-weight: 400;
                font-display:"swap";
                src: url("${MontserratThin}") format("truetype");
              }
              @font-face {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-display:"swap";
                src: url("${MontserratThin}") format("truetype");
              }
              @font-face {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-display:"swap";
                src: url("${MontserratMedium}") format("truetype");
              }
              @font-face {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-display:"swap";
                src: url("${MontserratBold}") format("truetype");
              }
              @font-face {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-display:"swap";
                src: url("${MontserratExtraBold}") format("truetype");
              }
              `,
      },
    },
    typography: {
      fontFamily: ['AvenirNext', 'Roboto', 'Helvetica', 'Arial', 'serif'].join(','),
    },
  });
  return baseTheme;
};

export default useBaseBirgeandheldTheme;
