import { AlertColor, Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import EventsModal from './events-modal';
import EventsDetail from './events-detail';
import CustomLoader from '@/src/components/custom-loader';
import { BoxEvents, EventsHeader, EventsPagesStylesDefault } from './Events.styled';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import EventsCard from './events-card';
import RefreshButton from '@/src/components/refresh-button/RefreshButton';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import { Events } from './types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

export const EventsPage = (props: Props) => {
  const {
    isLoading,
    orderedData,
    idSelected,
    setRefreshGrid,
    handleClick,
    isMobile,
    data,
    setIdSelected,
    openModal,
    setOpenModal,
    SnackBar,
    setSnackBarMessage,
  } = props;

  const { t } = useTranslation();

  return (
    <Box sx={!isLoading && orderedData?.length < 1 ? EventsPagesStylesDefault : { height: 'auto' }}>
      <EventsHeader pr={idSelected ? '20px' : '10px'}>
        <BoxEvents>
          <Typography variant="h5" component="h1">
            {t('events_publications', { ns: 'events' })}
          </Typography>
          <RefreshButton refresh={setRefreshGrid} disabled={isLoading} />
        </BoxEvents>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          size="small"
          color="primary"
          sx={{ width: { sm: 'auto', xs: 'auto' } }}
          onClick={handleClick}
        >
          {t('new_publication', { ns: 'events' })}
        </Button>
      </EventsHeader>
      <Grid container justifyContent="center">
        <Grid
          rowGap="20px"
          container
          item
          lg={idSelected ? 4 : 12}
          md={idSelected ? 4 : 12}
          sx={{
            maxHeight: `${isMobile ? 'auto' : '100%'}`,
            height: `${idSelected ? '850px' : '100%'}`,
            display: `${isMobile && idSelected && 'none'}`,
            overflow: 'auto',
            width: '100%',
          }}
        >
          {isLoading ? (
            <LoaderContainer>
              <CustomLoader />
            </LoaderContainer>
          ) : (
            ((!idSelected && isMobile) || !isMobile) &&
            data?.events?.length > 0 &&
            orderedData?.map((item: Events) => (
              <Grid
                key={item.id}
                item
                lg={idSelected ? 12 : 4}
                md={idSelected ? 12 : 6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: '10px',
                }}
              >
                <EventsCard item={item} setIdSelected={setIdSelected} />
              </Grid>
            ))
          )}
          {!isLoading && orderedData?.length < 1 && (
            <Box sx={{ margin: 'auto' }}>
              <AlertNoData title={t('no_data_was_found')} />
            </Box>
          )}
        </Grid>
        {idSelected && (
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <EventsDetail
              idSelected={idSelected}
              setIdSelected={setIdSelected}
              setSnackBarMessage={setSnackBarMessage}
              resource="events"
            />
          </Grid>
        )}
      </Grid>
      <EventsModal openModal={openModal} setOpenModal={setOpenModal} setSnackBarMessage={setSnackBarMessage} />
      <SnackBar />
    </Box>
  );
};

const propTypes = {};


interface ExtraProps {
  isLoading: boolean;
  orderedData: any;
  idSelected: number | null;
  setRefreshGrid: (value: boolean) => void;
  handleClick: () => void;
  isMobile: boolean;
  data: any;
  setIdSelected: (value: number | null) => void;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  SnackBar: () => JSX.Element;
  setSnackBarMessage: (msj: string, sever?: AlertColor | undefined) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
EventsPage.propTypes = propTypes;

export default EventsPage;
