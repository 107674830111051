import React from 'react';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import CustomTabPanel from './CustomTabPanel';

const CustomTabPanelContainer = (props: Props) => {
  const { children, value, index } = props;

  const childProps = {
    children,
    value,
    index,
  };

  return <CustomTabPanel {...childProps} />;
};

const propTypes = {};



interface ExtraProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CustomTabPanelContainer.propTypes = propTypes;

export default CustomTabPanelContainer;
