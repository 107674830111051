import { AlertColor, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import EventsForm from './events-form/EventsForm.container';
import { CloseConfirmationModal } from './CloseConfirmationModal';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { ArticleByIdBody } from '../types';

const EventsModal = (props: any) => {
  const {
    openModal,
    handleClose,
    dataById,
    handleCloseConfirmation,
    refetchDataById,
    setSnackBarMessage,
    openCreateModalCloseConfirmation,
    handleBackToCreating,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth="sm" disableEnforceFocus>
      <DialogTitle>
        {dataById ? t('edit', { ns: 'cdtModal' }) : t('new_publication', { ns: 'events' })}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <EventsForm
          dataById={dataById?.event}
          close={handleCloseConfirmation}
          refetchDataById={refetchDataById}
          setSnackBarMessage={setSnackBarMessage}
        />
        {openCreateModalCloseConfirmation && (
          <CloseConfirmationModal
            openCreateModalCloseConfirmation={openCreateModalCloseConfirmation}
            handleBackToCreating={handleBackToCreating}
            handleCloseConfirmation={handleCloseConfirmation}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const propTypes = {};

interface ExtraProps {
  openModal: boolean;
  handleClose: () => void;
  dataById?: ArticleByIdBody;
  handleCloseConfirmation: () => void;
  refetchDataById?: () => void;
  setSnackBarMessage: (sms: string, sever?: AlertColor) => void;
  openCreateModalCloseConfirmation: () => void;
  handleBackToCreating: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
EventsModal.propTypes = propTypes;

export default EventsModal;
