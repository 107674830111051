import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterByColumn } from '../../../types/CustomDataTableColumnProps';
import FilterMinMax from './FilterMinMax';

const FilterMinMaxContainer = (props: FilterMinMaxContainerProps) => {
  const { filter, localFilterString, setLocalFilterString, isNumeric = false } = props;
  const { t } = useTranslation();

  const [startValue, setStartValue] = React.useState<string | number>('');
  const [endValue, setEndValue] = React.useState<string | number>('');

  const getInitialValues = () => {
    const variables = localFilterString.split('&');
    variables.shift();
    variables.forEach((v) => {
      if (v.includes(`${filter.query}Start`)) {
        setStartValue(Number(v.split('=')[1]));
      } else if (v.includes(`${filter.query}End`)) {
        setEndValue(Number(v.split('=')[1]));
      }
    });
  };

  React.useEffect(() => {
    getInitialValues();
  }, []);

  const handleFilters = (query: string, value: number | string) => {
    setLocalFilterString((prev) => {
      const selectedValue = `&${query}=${value}`;
      if (prev.includes(query)) {
        const variables = prev.split('&');
        variables.shift();
        let str = '';
        variables.forEach((i) => {
          if (i.includes(query)) {
            str += `&${query}=${value}`;
          } else if (i !== '') {
            str += `&${i}`;
          }
        });
        return str;
      }
      return prev.concat(selectedValue);
    });
  };

  const onChange = (value: number | string, type: 'start' | 'end') => {
    const filterQuery = `${filter.query}${type === 'start' ? 'Start' : 'End'}`;
    handleFilters(filterQuery, value);

    if (type === 'start') {
      setStartValue(value);
    } else {
      setEndValue(value);
    }
  };

  React.useEffect(() => {
    if (!localFilterString) {
      setStartValue('');
    }
  }, [localFilterString]);

  const childProps = {
    filter,
    startValue,
    endValue,
    onChange,
    t,
  };

  return <FilterMinMax {...childProps} />;
};

interface FilterMinMaxContainerProps {
  setLocalFilterString: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterByColumn;
  localFilterString: string;
  isNumeric?: boolean | null;
}

export default FilterMinMaxContainer;
