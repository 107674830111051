import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikValues } from 'formik';
import ResidencyTypesAutocomplete from './ResidencyTypesAutocomplete';
import { getResidencyTypes } from '@/src/api/endpoints/master';
import { ResidencyType } from '@/src/utilities/helpers/commonTypes';

const ResidencyTypesAutocompleteContianer = (props: ResidencyTypesAutocompleteContainerProps) => {
  const { value: publicValue, onChange, id, errors } = props;
  const { data } = useQuery(['getResidencyTypes'], getResidencyTypes, {
    select: (response) => response?.data.residency_types as Array<ResidencyType>,
  });
  const { t } = useTranslation();

  const getInitialState = () => {
    if (!data) return null;
    if (!publicValue === null) return null;
    if (typeof publicValue === 'number') return data.find((item) => item.id === publicValue) ?? null;
    if (typeof publicValue === 'string') {
      const codedValue = data?.find((item) => item.code === publicValue);
      if (codedValue) return codedValue;
      return data.find((item) => item.name === publicValue) ?? null;
    }
    return publicValue as ResidencyType;
  };

  const [privateValue, setPrivateValue] = React.useState<ResidencyType | null>(null);

  React.useEffect(() => {
    setPrivateValue(getInitialState());
  }, [data, publicValue]);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: ResidencyType | null) => {
    setPrivateValue(value);
    onChange(e, value);
  };

  const childProps = {
    data,
    value: privateValue,
    onChange: handleChange,
    id,
    t,
    errors,
  };

  return <ResidencyTypesAutocomplete {...childProps} />;
};

interface ResidencyTypesAutocompleteContainerProps {
  id?: string;
  onChange: (e: React.SyntheticEvent<Element, Event>, value: ResidencyType | null) => void;
  value: ResidencyType | string | number | null;
  errors?: FormikErrors<FormikValues>;
}

export default ResidencyTypesAutocompleteContianer;
