import PropTypes from 'prop-types';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import BuildingSelector from './BuildingSelector';
import { Buildingvalues, Tower } from '@/src/pages/building-swap/types';
import { useGetChildren } from '@/src/hooks/common/useGetChildren';
import { AuthContext } from '@/src/context/auth.context';

export const BuildingSelectorContainer = (props: Props) => {
  const { t, values, setValues, setSecondGroupLogin, setThirdGroupLogin } = props;

  // building used and the array of buildings
  const { buildingsUsed } = useContext(AuthContext);
  // towers group
  const [secondTowersGroup, setSecondTowersGroup] = useState<Tower[]>(buildingsUsed?.secondBuildingsGroup || []);
  const [thirdTowersGroup, setThirdTowersGroup] = useState<Tower[]>(buildingsUsed?.thirdBuildingsGroup || []);

  // hooks
  const { getChildrens } = useGetChildren();

  const handleRefetchTower = async (
    tenantToRefetch: string,
    seterFunction: Dispatch<SetStateAction<Tower[]>>,
    seterOnLoginFunction?: Dispatch<SetStateAction<Tower[]>> | null,
  ) => {
    getChildrens(tenantToRefetch, {
      onSuccess({ data }) {
        seterFunction(data?.client?.children);
        if (seterOnLoginFunction) seterOnLoginFunction(data?.client?.children);
      },
    });
  };

  const handleAssignValues = (
    firstTowerValue: any,
    secondTowerValue: any,
    thirdTowerValue: any,
    tenantValue: string,
  ) => {
    setValues({
      firstTowerInstance: firstTowerValue,
      secondTowerInstance: secondTowerValue,
      thirdTowerInstance: thirdTowerValue,
      codeTenant: tenantValue,
    });
  };

  const handleFirstTowerChange = (event: any, value: any) => {
    if (!value?.has_children) {
      handleAssignValues(value, null, null, value?.client_tenant_code);
      setSecondTowersGroup([]);
      if (setSecondGroupLogin) setSecondGroupLogin([]);
      setThirdTowersGroup([]);
      if (setThirdGroupLogin) setThirdGroupLogin([]);
    } else {
      if (setSecondGroupLogin) {
        handleRefetchTower(value?.client_tenant_code, setSecondTowersGroup, setSecondGroupLogin);
      } else {
        handleRefetchTower(value?.client_tenant_code, setSecondTowersGroup);
      }
      handleAssignValues(value, null, null, value?.client_tenant_code);
      setThirdTowersGroup([]);
      if (setThirdGroupLogin) setThirdGroupLogin([]);
    }
  };

  const handleSecondTowerChange = (event: any, value: any) => {
    if (!value?.has_children) {
      handleAssignValues(values?.firstTowerInstance, value, null, value?.client_tenant_code);
      setThirdTowersGroup([]);
      if (setThirdGroupLogin) setThirdGroupLogin([]);
    } else {
      if (setThirdGroupLogin) {
        handleRefetchTower(value?.client_tenant_code, setThirdTowersGroup);
      } else {
        handleRefetchTower(value?.client_tenant_code, setThirdTowersGroup, setThirdGroupLogin);
      }
      handleAssignValues(values?.firstTowerInstance, value, null, value?.client_tenant_code);
    }
  };

  const handleThirdTowerChange = (event: any, value: any) => {
    handleAssignValues(values?.firstTowerInstance, values?.secondTowerInstance, value, value?.client_tenant_code);
  };

  const childProps = {
    ...props,
    t,
    values,
    firstTowersGroup: buildingsUsed?.firstBuildingsGroup,
    secondTowersGroup,
    thirdTowersGroup,
    handleFirstTowerChange,
    handleSecondTowerChange,
    handleThirdTowerChange,
  };

  return <BuildingSelector {...childProps} />;
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  values: Buildingvalues;
  setValues: React.Dispatch<React.SetStateAction<Buildingvalues>>;
  setSecondGroupLogin?: React.Dispatch<React.SetStateAction<Tower[]>> | null;
  setThirdGroupLogin?: React.Dispatch<React.SetStateAction<Tower[]>> | null;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}

BuildingSelectorContainer.propTypes = propTypes;

export default BuildingSelectorContainer;
